<template>
    <div class="amazon-success">
        <h1>Authorization Successful!</h1>
        <p>You have successfully authorized the 10XCrew app with your Amazon login.</p>
    </div>
</template>

<style scoped>
.amazon-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}
</style>