<template>
    <div>

        <div id="controls_container">
            <div id="filter_bar">
                <input type="text" v-model="nameFilter" placeholder="Filter by name...">
                <select v-model="statusFilter">
                    <option value="">All Statuses</option>
                    <option value="running">Running</option>
                    <option value="finished">Finished</option>
                    <option value="crashed">Crashed</option>
                </select>
            </div>
            <div id="task_controls">
                <button @click="startAllTasks">Start All</button>
                <button @click="stopAllTasks">Stop All</button>
            </div>
        </div>
        <table>
            <thead>
                <tr id="table_header_row">
                    <th>Name</th>
                    <th>Status</th>
                    <th>Progress</th>
                    <th>Control</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="task in paginatedTasks" :key="task.id">
                    <td>{{ task.name }}</td>
                    <td :class="statusClass(task.status)">{{ task.status }}</td>
                    <td>{{ task.progress }}</td>
                    <td>
                        <button @click="controlTask(task.id)">Control</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div>
            <button @click="prevPage" :disabled="currentPage <= 1">Prev</button>
            <button @click="nextPage" :disabled="currentPage >= totalPages">Next</button>
            <select v-model="recordsPerPage" @change="currentPage = 1">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="40">40</option>
            </select>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

const tasks = ref([]);
const currentPage = ref(1);
const recordsPerPage = ref(10);
const nameFilter = ref('');
const statusFilter = ref('');

onMounted(async () => {
    await fetchTasks();
});

async function startAllTasks() {
    try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/dashboard/tasks/start_all`);
        console.log('All tasks started');
        await fetchTasks();
    } catch (error) {
        console.error('Failed to start tasks:', error);
    }
}

async function stopAllTasks() {
    try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/dashboard/tasks/stop_all`);
        console.log('All tasks stopped');
        await fetchTasks();
    } catch (error) {
        console.error('Failed to stop tasks:', error);
    }
}

async function fetchTasks() {
    try {
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/dashboard/tasks`);
        tasks.value = Array.isArray(response.data.tasks) ? response.data.tasks : [];
    } catch (error) {
        console.error('Failed to fetch tasks:', error);
        tasks.value = [];
    }
}

function controlTask(taskId) {
    console.log('Controlling task', taskId);
}

function statusClass(status) {
    switch (status) {
        case 'running':
            return 'status-running';
        case 'finished':
            return 'status-finished';
        case 'crashed':
            return 'status-crashed';
        default:
            return '';
    }
}

const filteredTasks = computed(() => {
    if (!Array.isArray(tasks.value) || tasks.value.length === 0) {
        return [];
    }

    return tasks.value.filter((task) => {
        return (nameFilter.value === '' || task.name.includes(nameFilter.value)) &&
                (statusFilter.value === '' || task.status === statusFilter.value);
    });
});

const paginatedTasks = computed(() => {
    const start = (currentPage.value - 1) * recordsPerPage.value;
    const end = start + parseInt(recordsPerPage.value, 10);
    return filteredTasks.value.slice(start, end);
});

const totalPages = computed(() => {
    return Math.ceil(filteredTasks.value.length / recordsPerPage.value) || 1;
});

function nextPage() {
    if (currentPage.value < totalPages.value) currentPage.value++;
}

function prevPage() {
    if (currentPage.value > 1) currentPage.value--;
}
</script>

<style scoped>
button {
    cursor: pointer;
    margin: 4px;
}
table {
    width: 100%;
    border-collapse: collapse;
}
th, td {
    border: 1px solid #ddd;
    padding: 4px;
    text-align: left;
}
#table_header_row th {
    padding: 8px;
}
th {
    background-color: #f4f4f4;
}

#controls_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

#task_controls button {
    margin: 4px;
}

#filter_bar {
    margin: 10px;
}
#filter_bar input, #filter_bar select {
    margin: 4px;
}

/* Added CSS classes for task statuses */
.status-running {
    background-color: orange;
}
.status-finished {
    background-color: green;
}
.status-crashed {
    background-color: red;
}
</style>