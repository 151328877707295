import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '@/components/TabController'
import AmazonLogin from '@/components/LoginControl'
import AmazonCallback from '@/components/amazon_auth_flow/AmazonCallback'
import AmazonSuccess from '@/components/amazon_auth_flow/AmazonSucces'
import AmazonFailed from '@/components/amazon_auth_flow/AmazonFailed'
import ChooseRegion from '@/components/amazon_auth_flow/ChooseRegion'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/amazon_auth_login',
        name: 'AmazonLogin',
        component: AmazonLogin
    },
    {
        path: '/amazon_auth_callback',
        name: 'AmazonCallback',
        component: AmazonCallback
    },
    {
        path: '/amazon_auth_succesful',
        name: 'AmazonLoginSuccess',
        component: AmazonSuccess
    },
    {
        path: '/amazon_auth_failed',
        name: 'AmazonLoginFailed',
        component: AmazonFailed
    },
    {
        path: '/choose_region/:username?',
        name: 'ChooseRegion',
        component: ChooseRegion
    }
    ]

    const router = createRouter({
        history: createWebHistory('/'),
        routes: routes,
    })

export default router;