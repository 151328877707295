<template>
    <div class="amazon-success">
        <h1>Authorization failed with code: {{ error }}</h1>
        <p>Please contact 10XCrew or try again later</p>
    </div>
</template>

<script>
export default {
    name: 'AmazonLoginFailed',
    data() {
        return {
            error: this.$route.params.error
        };
    }
};
</script>

<style scoped>
.amazon-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}
</style>