<template>
    <div class="choose-region">
        <header class="header">
            <h1>Choose region to authenticate:</h1>
        </header>
        <div class="button-container">
            <button class="button" @click="makeBackendCall('EU')">Europe</button>
            <div class="button-space"></div>
            <button class="button" @click="makeBackendCall('US')">United states</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ChooseRegion',
    methods: {
        makeBackendCall(region) {
            const username = this.$route.params.username;

            console.log(process.env.VUE_APP_BASE_URL)

            axios.get(`${process.env.VUE_APP_BASE_URL}/auth_url`, { params: { region: region, username: username } })
                .then(response => {
                    console.log(response)
                    if (response.status === 200) {
                        // window.location.href = response.data.auth_url;
                    }
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>
.choose-region {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    margin-top: calc(33.33vh - 50px);
    margin-bottom: auto;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    font-size: 20px;
    padding: 10px 20px;
    cursor: pointer;
}

.button-space {
    width: 50px;
}
</style>