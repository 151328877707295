<template>
    <div>
        <div class="tabs-container">
            <div
                v-for="(tab, index) in tabs"
                :key="index"
                class="tab"
                :class="{ 'active-tab': activeTab === index }"
                @click="setActiveTab(index)"
            >
            {{ tab.label }}
            </div>
        </div>
        <div class="tab-content">
            <component :is="tabs[activeTab].component" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import TaskTab from './dashboard_tabs/TaskTab.vue';
import LogsTab from './dashboard_tabs/LogsTab.vue';
import SettingsTab from './dashboard_tabs/SettingsTab.vue';
import DataStatusTab from './dashboard_tabs/DataStatusTab.vue';

const tabs = ref([
    { label: 'Data Status', component: DataStatusTab },
    { label: 'Task Control', component: TaskTab },
    { label: 'Logs Control', component: LogsTab },
    { label: 'Settings Control', component: SettingsTab },
]);

const activeTab = ref(0);

const setActiveTab = (index) => {
    activeTab.value = index;
};
</script>

<style scoped>
.tabs-container {
    display: flex;
    border-bottom: 2px solid #e0e0e0;
    padding: 0;
    margin: 0;
    list-style: none;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #d0d0d0;
    margin-right: 4px;
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    top: 1px;
}

.active-tab {
    background-color: #fff;
    border-color: #e0e0e0;
    font-weight: bold;
    top: 2px;
}
</style>