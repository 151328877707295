<template>
    <div>
        <div v-if="loading">
            <i class="fa fa-spinner fa-spin"></i>
            <p>Processing...</p>
        </div>
        <div v-else>
            <p>Call to backend completed successfully!</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            loading: true
        };
    },
    mounted() {
        this.makeBackendCall();
    },
    methods: {
        makeBackendCall() {
            const urlParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlParams.entries());

            const isDevelopment = process.env.NODE_ENV === 'development';
            const frontendRedirectUrl = isDevelopment ? 'http://localhost:8080/amazon_auth_successful' : '/amazon_auth_successful';

            axios.get(`${process.env.VUE_APP_BASE_URL}/store_auth_code`, { params })
                .then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        window.location.href = frontendRedirectUrl;
                    }
                })
                .catch(error => {
                    this.$router.push({
                        name: 'AmazonLoginFailed',
                        params: {
                            error: error
                        }
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style>
.fa-spinner {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>