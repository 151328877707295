<template>
    <div>
    <h2>Marketplace Settings</h2>
    <table>
        <thead>
        <tr>
            <th>Marketplace Name</th>
            <th>Active Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="marketplace in marketplaces" :key="marketplace.name">
            <td>{{ marketplace.name }}</td>
            <td>
            <label class="switch">
                <input type="checkbox" v-model="marketplace.active" @change="toggleActive(marketplace)">
                <span class="slider round"></span>
            </label>
            </td>
        </tr>
        </tbody>
    </table>
    </div>
</template>

<script>
export default {
    data() {
    return {
        marketplaces: [
        {
            name: "bol",
            active: false,
        },
          // Additional marketplaces can be added here
        ],
    };
    },
    methods: {
    toggleActive(marketplace) {
        marketplace.active = !marketplace.active;
        // Implement the backend update logic
        console.log(`Marketplace ${marketplace.name} active status: ${marketplace.active}`);
    },
    },
};
</script>

<style>
  /* Basic table styles */
table {
    width: 100%;
    border-collapse: collapse;
}
th, td {
    border: 1px solid #ddd;
    padding: 8px;
}

  /* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

  /* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

  /* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    }
    
    .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

  /* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>